body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: antiquewhite;

  display: flex;
  justify-content: center;
  align-items: center;
}

.definicion {
  color: cornflowerblue;
  margin-right: 10px;
}

.funcion {
  margin-right: 10px;
}

.asignacion {
  margin-right: 15px;
  color: white;
}

.parametro {
  margin-right: 15px;
  color: darkolivegreen;
}

.flecha {
  color: cornflowerblue;
}

.parentesis {
  color: yellow;
}

.string {
  color: sandybrown;
}
